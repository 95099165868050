@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-roboto;
  }
  h1, h2, h3, h4, h5, h6 {
    @apply font-anton tracking-wide;
  }
}
